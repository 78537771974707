import { IModel, ModelEffect, ModelReducer, ModelState, ModelStateSelector } from '../model';
import merge from 'lodash/merge';
import { useDispatch } from 'umi';

export interface DispatchWorkState extends ModelState {
  tabs: { [key: string]: any; };
  tabKey: string;
  details: { [key: string]: any[] };
  tableAddon: any;
  partRow: any;
  delIds: any[];
  makeBatch: any[];
}

export interface IDispatchWorkModel extends IModel<DispatchWorkState> {
  effects: {};

  reducers: {
    save: ModelReducer<DispatchWorkState>;
    addTab: ModelReducer<DispatchWorkState>;
    removeTab: ModelReducer<DispatchWorkState>;
    saveDetail: ModelReducer<DispatchWorkState>;
    saveTableAddon: ModelReducer<DispatchWorkState>;
    saveDetailTableAddon: ModelReducer<DispatchWorkState>;
    saveDetailTableAddonAny: ModelReducer<DispatchWorkState>;
    addDelId: ModelReducer<DispatchWorkState>;
    init: ModelReducer<DispatchWorkState>;
  };
}

export const DispatchWorkStateSelector: ModelStateSelector<DispatchWorkState> = (state: any) => state.DispatchWork;

export function useDispatchWorkReducers(): (action: keyof IDispatchWorkModel['reducers'], payload?: any) => any {
  const dispatch = useDispatch();

  return (action, payload) => {
    return dispatch({
      type: `DispatchWork/${action}`,
      payload
    });
  }
}

const DispatchWorkModel: IDispatchWorkModel = {
  state: {
    tabs: {},
    tabKey: '',
    details: {},
    tableAddon: {},
    partRow: {},
    delIds: [],
    makeBatch: []
  },

  effects: {},

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },

    init() {
      return {
        tabs: {},
        tabKey: '',
        details: {},
        tableAddon: {},
        partRow: {},
        delIds: [],
        makeBatch: []
      };
    },

    addTab(state, { payload }) {
      let dts: any[] = [];
      if (payload.copyFrom) {
        dts = state.details[payload.copyFrom] || [];
      }

      if (payload.parts) {
        dts = payload.parts.map((r: any) => {
          const item: any = {
            materialCode: r.materialCode,
            materialName: r.materialName,
            usePartName: r.partName,
            rowState: "ENABLED",
            colorName: payload.colorName,
            styleCode: r.styleCode,
            styleName: r.styleName,
            key: `${r.styleCode}_${r.partName}`,
            sizes: []
          };

          const sizeMap = payload.partSizeMap?.[r.partName] || [];
          if (r.autoSize && sizeMap.length > 0) {
            item.sizes = sizeMap.map((p: any) => p);
          }

          if (payload.batchData) {
            item.sizes = [];

            payload.batchData.forEach((b: any) => {
              const find = sizeMap.find((s: any) => s.sizeCode === b.sizeCode);

              if (find) {
                item.sizes.push({
                  ...find,
                  batchCount: b.batchCount,
                  dispatchCount: b.batchCount,
                  dispatchUserName: payload.userName,
                  dispatchUserCode: payload.userCode
                });
              }
            });
          }

          return item;
        });
      }

      return {
        ...state,
        tabs: {
          ...state.tabs,
          [payload.dispatchOrderCode]: {
            dispatchOrderCode: payload.dispatchOrderCode,
            produceBatchName: payload.produceBatchName,
            batchData: payload.batchData || []
          }
        },
        details: {
          ...state.details,
          [payload.dispatchOrderCode]: dts
        },
        tabKey: payload.dispatchOrderCode
      };
    },

    removeTab(state, { payload }) {
      let { tabs, details, tabKey } = state;

      delete tabs[payload];
      delete details[payload];

      if (payload === tabKey) {
        return {
          ...state,
          tabs,
          details,
          tabKey: Object.keys(tabs)[0] || '',
          partRow: {},
          tableAddon: {}
        };
      }

      return {
        ...state,
        tabs,
        details
      };
    },

    saveTableAddon(state, { payload }) {
      const [key, values] = payload;

      return {
        ...state,
        tableAddon: {
          ...state.tableAddon,
          ...{
            [key]: {
              ...state.tableAddon[key],
              ...values
            }
          }
        }
      };
    },

    saveDetail(state, { payload }) {
      return {
        ...state,
        details: {
          ...state.details,
          ...payload
        }
      };
    },

    addDelId(state, { payload }) {
      return {
        ...state,
        delIds: Array.from(new Set(state.delIds.concat(payload)))
      };
    },

    saveDetailTableAddon(state, { payload }) {
      const {
        partRow,
        tabKey,
        tableAddon
      } = state;
      const antoKey = payload && payload?.antoKey ? payload.antoKey : '';

      return (partRow.key && tabKey) ? {
        ...state,
        details: {
          ...state.details,
          [tabKey]: state.details[tabKey].map(dt => {
            if (partRow.key === dt.key) {
              return {
                ...dt,
                sizes: dt.sizes.map((s: any) => {
                  const newKey = antoKey && s[antoKey] ? s[antoKey]  : s.key
                  if (tableAddon[newKey]) {
                    return { ...s, ...tableAddon[newKey] }
                  }

                  return s;
                })
              };
            }

            return dt;
          })
        }
      } : state;
    },


    saveDetailTableAddonAny(state, { payload }) {
      const {
        partRow,
        tabKey,
        tableAddon
      } = state;
      const { anyListName } = payload;

      return (partRow.key && tabKey) ? {
        ...state,
        details: {
          ...state.details,
          [tabKey]: state.details[tabKey].map(dt => {
            if (partRow.key === dt.key && dt[anyListName]) {
              return {
                ...dt,
                [anyListName]: dt[anyListName].map((s: any) => {
                  if (tableAddon[s.key]) {
                    return { ...s, ...tableAddon[s.key] }
                  }

                  return s;
                })
              };
            }

            return dt;
          })
        }
      } : state;
    }
  }
}

export default DispatchWorkModel;
