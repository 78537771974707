
export default {
  loading: '加载中...',

  mesPageContainer: {
    extraContent: {
      rs: '状态',
    }
  }
};
