
import { useDispatch } from 'umi';
import { IModel, ModelEffect, ModelReducer, ModelState, ModelStateSelector } from '../model';
import merge from 'lodash/merge';

export interface BomDetailState extends ModelState {
  materials: { [key: string]: any };
  optionData: { [key: string]: any[] };
  tableData: any[];
  tableLoading: boolean;
  delIds: any[];
  dimensions: any;
  styleColor: any;
  editRow: any;
  selRow: any;
  sorting: boolean;
  styleDimensions: any;
  styleColorOptions: any[];
  originData: any[];
  materialVisible: boolean;
  addVisible: boolean;
}

export interface IBomDetailModel extends IModel<BomDetailState> {
  effects: {};

  reducers: {
    init: ModelReducer<BomDetailState>;
    save: ModelReducer<BomDetailState>;
    updateRows: ModelReducer<BomDetailState>;
    updateOptions: ModelReducer<BomDetailState>;
    addDelIds: ModelReducer<BomDetailState>;
    removeTableData: ModelReducer<BomDetailState>;
    addMaterial: ModelReducer<BomDetailState>;
    updateMaterial: ModelReducer<BomDetailState>;
    updateDimension: ModelReducer<BomDetailState>;
    updateStyleDimension: ModelReducer<BomDetailState>;
    copyBom: ModelReducer<BomDetailState>;
    sortBom: ModelReducer<BomDetailState>;
  };
}

export const BomDetailStateSelector: ModelStateSelector<BomDetailState> = (state: any) => state.BomDetail;

export function useBomDetailReducers (): (action: keyof IBomDetailModel['reducers'], payload?: any) => any {
  const dispatch = useDispatch();

  return (action, payload) => {
    return dispatch({
      type: `BomDetail/${action}`,
      payload
    });
  }
}

export function useBomDetailEffects(): (action: keyof IBomDetailModel['effects'], payload?: any) => Promise<any> {
  const dispatch = useDispatch();

  return (action, payload) => {
    return dispatch({
      type: `BomDetail/${action}`,
      payload
    }) as any;
  }
}

const BomDetailModel: IBomDetailModel = {
  state: {
    materials: {},
    optionData: {},
    tableData: [],
    tableLoading: false,
    delIds: [],
    dimensions: {},
    styleColor: {},
    editRow: {},
    selRow: {},
    styleColorOptions: [],
    sorting: false,
    styleDimensions: {},
    originData: [],
    materialVisible: false,
    addVisible: false,
  },

  effects: {},

  reducers: {
    init(state, { payload }) {
      return {
        materials: {},
        optionData: {},
        tableData: [],
        tableLoading: false,
        delIds: [],
        dimensions: {},
        styleColor: {},
        editRow: {},
        styleDimensions: {},
        selRow: {},
        sorting: false,
        styleColorOptions: [],
        ...payload
      };
    },

    copyBom(state, { payload }) {
      return {
        ...state,
        delIds: state.delIds.concat(state.tableData.filter(t => t.id.indexOf('AUTO') === -1).map(t => t.id)),
        tableData: payload,
        editRow: {}
      };
    },

    sortBom(state, { payload }) {
      const { oldIndex, newIndex } = payload;

      const tableData = [ ...state.tableData ];

      if (oldIndex !== newIndex) {
        const oldValue = { ...tableData[oldIndex] };
        tableData.splice(oldIndex, 1);
        tableData.splice(newIndex, 0, oldValue);
      }

      return {
        ...state,
        tableData
      };
    },

    save(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },

    updateRows(state, { payload }) {
      return {
        ...state,
        materials: merge(state.materials, payload)
      };
    },

    updateStyleDimension(state, { payload }) {
      return {
        ...state,
        styleDimensions: {
          ...state.styleDimensions,
          ...payload
        }
      };
    },

    updateDimension(state, { payload }) {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          ...payload
        }
      };
    },

    addMaterial(state, { payload }) {
      const {
        materials, tableData
      } = payload;

      return {
        ...state,
        materials: {
          ...state.materials,
          ...materials
        },
        addVisible: false,
        tableData: state.tableData.concat(tableData)
      };
    },

    updateMaterial(state, { payload }) {
      const tableData = [ ...state.tableData ];
      let delIds = [ ...state.delIds ];


      if (payload.findIndex > -1) {
        tableData[payload.findIndex] = payload.newItem;

        return {
          ...state,
          tableData,
          delIds,
          addVisible: false,
          materials: {
            ...state.materials,
            [payload.newItem.id]: payload.newItem,
          },
        };
      }

      return state;
    },

    updateOptions(state, { payload }) {
      return {
        ...state,
        optionData: {
          ...state.optionData,
          ...payload
        }
      };
    },

    addDelIds(state, { payload }) {
      return {
        ...state,
        delIds: state.delIds.concat(payload)
      }
    },

    removeTableData(state, { payload }) {
      const { id } = payload;
      let tableData = state.tableData;
      let delIds = state.delIds;
      const dataRow = tableData.findIndex(t => t.id === id);

      if (id && dataRow > -1) {
        delIds = delIds.concat((tableData[dataRow]._rows || [])
          .filter((r: any) => r.id)
          .map((r: any) => r.id));

        tableData = tableData.filter(t => t.id !== id);
      }

      return {
        ...state,
        tableData,
        delIds
      };
    }
  }
};

export default BomDetailModel;
