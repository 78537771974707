// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/mnt1/build/mes-pc/mes/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@C/components/layout/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "redirect": "/test",
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__layout__BasicLayout' */'@/components/layout/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/test",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__test' */'@/pages/test'), loading: LoadingComponent}),
        "access": false,
        "exact": true
      },
      {
        "path": "/ie/stage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Stage__List' */'@/pages/Stage/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/stage/section",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Stage__Section' */'@/pages/Stage/Section'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/style/stage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Stage' */'@/pages/Style/Stage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/style/part/match",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Match' */'@/pages/Style/Match'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/style/part/section",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Section' */'@/pages/Style/Section'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/line/stage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Line' */'@/pages/Style/Line'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/line/stage/view",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Line__view' */'@/pages/Style/Line/view'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/parts/craft",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__Craft' */'@/pages/IE/Craft'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/style/lineBase",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__StagePrice' */'@/pages/Style/StagePrice'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/style/lineBase/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__LineBase__Add' */'@/pages/IE/LineBase/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/style/lineBase/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__LineBase__Add' */'@/pages/IE/LineBase/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/style/lineBase/wages",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__LineBase__ProcedureWages' */'@/pages/IE/LineBase/ProcedureWages'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ie/style/lineBase/stage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__LineBase__Stage' */'@/pages/IE/LineBase/Stage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/sop",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Sop' */'@/pages/Style/Sop'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__List__tab' */'@/pages/Style/List/tab'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/bom/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Bom__List' */'@/pages/Style/Bom/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/bom/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Bom__Material' */'@/pages/Style/Bom/Material'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/bom/view",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Bom__View' */'@/pages/Style/Bom/View'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/technics/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Technics' */'@/pages/Style/Technics'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/cost",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Cost' */'@/pages/Style/Cost'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/cost/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Cost__Add' */'@/pages/Style/Cost/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/cost/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Cost__Add' */'@/pages/Style/Cost/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/cost/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Style__Cost__Detail' */'@/pages/Style/Cost/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/plan/cc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Plan__cc' */'@/pages/Dispatch/Plan/cc'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/plan/sg",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Plan__sg' */'@/pages/Dispatch/Plan/sg'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/plan/wx",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Plan__wx' */'@/pages/Dispatch/Plan/wx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/plan/dnc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Plan__dnc' */'@/pages/Dispatch/Plan/dnc'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC' */'@/pages/Dispatch/Work/Section/ZC'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zc/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Edit' */'@/pages/Dispatch/Work/Section/ZC/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zc/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Detail' */'@/pages/Dispatch/Work/Section/ZC/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zc/scan",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Report__Scan' */'@/pages/Dispatch/Work/Section/ZC/Report/Scan'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zc/done",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Done' */'@/pages/Dispatch/Work/Section/ZC/Done'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zc/report/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Report__Add' */'@/pages/Dispatch/Work/Section/ZC/Report/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zc/apply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Apply' */'@/pages/Dispatch/Work/Section/ZC/Apply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zc/apply/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Apply__Edit' */'@/pages/Dispatch/Work/Section/ZC/Apply/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zc/apply/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Apply__Detail' */'@/pages/Dispatch/Work/Section/ZC/Apply/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/sg",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__SG' */'@/pages/Dispatch/Work/Section/SG'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/sg/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__SG__Edit__add' */'@/pages/Dispatch/Work/Section/SG/Edit/add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/sg/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__SG__Edit' */'@/pages/Dispatch/Work/Section/SG/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/sg/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__SG__Detail__index' */'@/pages/Dispatch/Work/Section/SG/Detail/index.tsx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/wx",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__WX' */'@/pages/Dispatch/Work/Section/WX'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/wx/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__WX__Edit__add' */'@/pages/Dispatch/Work/Section/WX/Edit/add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/wx/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__WX__Edit' */'@/pages/Dispatch/Work/Section/WX/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/wx/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__WX__Detail__index' */'@/pages/Dispatch/Work/Section/WX/Detail/index.tsx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/dnc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__DNC' */'@/pages/Dispatch/Work/Section/DNC'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/dnc/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__DNC__Edit__add' */'@/pages/Dispatch/Work/Section/DNC/Edit/add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/dnc/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__DNC__Edit' */'@/pages/Dispatch/Work/Section/DNC/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/dnc/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__DNC__Detail__index' */'@/pages/Dispatch/Work/Section/DNC/Detail/index.tsx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC' */'@/pages/Dispatch/Work/Section/CC'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Edit__add' */'@/pages/Dispatch/Work/Section/CC/Edit/add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/part",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__part' */'@/pages/Dispatch/Work/Section/CC/part.tsx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Edit' */'@/pages/Dispatch/Work/Section/CC/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Detail__index' */'@/pages/Dispatch/Work/Section/CC/Detail/index.tsx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/done",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Done' */'@/pages/Dispatch/Work/Section/CC/Done'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/process/cc/part",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Process__Part' */'@/pages/Dispatch/Work/Section/CC/Process/Part'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/apply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Apply' */'@/pages/Dispatch/Work/Section/CC/Apply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/apply/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Apply__Edit' */'@/pages/Dispatch/Work/Section/CC/Apply/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/apply/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Apply__Detail' */'@/pages/Dispatch/Work/Section/CC/Apply/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/report",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Report__list' */'@/pages/Dispatch/Work/Section/CC/Report/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/batch",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__batch' */'@/pages/Dispatch/Work/Section/CC/batch'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/sg/batch",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__SG__batch' */'@/pages/Dispatch/Work/Section/SG/batch'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/wx/batch",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__WX__batch' */'@/pages/Dispatch/Work/Section/WX/batch'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/dnc/batch",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__DNC__batch' */'@/pages/Dispatch/Work/Section/DNC/batch'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/report/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Report__List__index' */'@/pages/Dispatch/Report/List/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cc/next/page",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Done__next' */'@/pages/Dispatch/Work/Section/CC/Done/next'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/sg/next/page",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__SG__Done__next' */'@/pages/Dispatch/Work/Section/SG/Done/next'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/wx/next/page",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__WX__Done__next' */'@/pages/Dispatch/Work/Section/WX/Done/next'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/dnc/next/page",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__DNC__Done__next' */'@/pages/Dispatch/Work/Section/DNC/Done/next'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cx",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CX' */'@/pages/Dispatch/Work/Section/CX'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cx/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CX__Edit' */'@/pages/Dispatch/Work/Section/CX/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cx/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CX__Detail' */'@/pages/Dispatch/Work/Section/CX/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cx/report/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CX__Report__Add' */'@/pages/Dispatch/Work/Section/CX/Report/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/report/cx/pm",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CX__Report__ScanPM' */'@/pages/Dispatch/Work/Section/CX/Report/ScanPM'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/cx/done",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CX__Done' */'@/pages/Dispatch/Work/Section/CX/Done'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/outsource/apply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Outsource__Apply' */'@/pages/Outsource/Apply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/outsource/apply/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Outsource__Apply__Edit' */'@/pages/Outsource/Apply/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/outsource/apply/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Outsource__Apply__Detail' */'@/pages/Outsource/Apply/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/outsource/apply/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Outsource__List' */'@/pages/Outsource/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/outsource/apply/in",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Outsource__List__in' */'@/pages/Outsource/List/in'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/make/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Make__List' */'@/pages/Make/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/make/list/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Make__Add' */'@/pages/Make/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/make/list/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Make__Detail' */'@/pages/Make/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/style/color",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MtStyleColor' */'@/pages/MtStyleColor'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/make/batch",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MakeBatch__List' */'@/pages/MakeBatch/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/make/batch/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MakeBatch__Add' */'@/pages/MakeBatch/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/supplement/apply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Supplement__List' */'@/pages/Dispatch/Supplement/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/supplement/apply/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Supplement__Add' */'@/pages/Dispatch/Supplement/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/supplement/apply/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Supplement__Detail' */'@/pages/Dispatch/Supplement/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/supplement/apply/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Supplement__Add__Edit' */'@/pages/Dispatch/Supplement/Add/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/supplement/apply/po",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Supplement__po' */'@/pages/Dispatch/Supplement/po'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/supplement/outApply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Supplement__OutList' */'@/pages/Dispatch/Supplement/OutList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/supplement/outApply/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Supplement__OutAdd' */'@/pages/Dispatch/Supplement/OutAdd'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/supplement/outApply/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Supplement__OutAdd__Edit' */'@/pages/Dispatch/Supplement/OutAdd/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/supplement/apply/outDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Supplement__Detail__Outindex' */'@/pages/Dispatch/Supplement/Detail/Outindex'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/defect",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__Defect' */'@/pages/Quality/Defect'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/defect/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__Defect__Add' */'@/pages/Quality/Defect/Add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/check",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__Check' */'@/pages/Quality/Check'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/defectAnalysis",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__DefectAnalysis' */'@/pages/Quality/DefectAnalysis'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/defectAnalysis/report",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__DefectAnalysis__Report' */'@/pages/Quality/DefectAnalysis/Report'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/defectAnalysis/zc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__DefectAnalysis__ZC' */'@/pages/Quality/DefectAnalysis/ZC'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/defectAnalysis/report/zc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__DefectAnalysis__Report' */'@/pages/Quality/DefectAnalysis/Report'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/checkRecord",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__CheckRecord' */'@/pages/Quality/CheckRecord'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/checkRecord/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__CheckRecord__Report' */'@/pages/Quality/CheckRecord/Report'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/quality/problem",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Quality__Problem__V1' */'@/pages/Quality/Problem/V1'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exam/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__Exam__index' */'@/pages/IE/Exam/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exam/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__Exam__edit' */'@/pages/IE/Exam/edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exam/publish",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__Exam__publish' */'@/pages/IE/Exam/publish'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exam/result",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__Exam__result' */'@/pages/IE/Exam/result'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exam/problem",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__IE__Exam__Problem__index' */'@/pages/IE/Exam/Problem/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/cx",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__CX' */'@/pages/Allocation/CX'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/cx/distribution",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__CX__Distribution' */'@/pages/Allocation/CX/Distribution'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/cx/audit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__CX__Audit' */'@/pages/Allocation/CX/Audit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/cx/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__CX__Detail' */'@/pages/Allocation/CX/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/zc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__ZC' */'@/pages/Allocation/ZC'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/zc/distribution",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__ZC__Distribution' */'@/pages/Allocation/ZC/Distribution'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/zc/audit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__ZC__Audit' */'@/pages/Allocation/ZC/Audit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/zc/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__ZC__Detail' */'@/pages/Allocation/ZC/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/dnc/audit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__DNC__Audit' */'@/pages/Allocation/DNC/Audit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/wx/audit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__WX__Audit' */'@/pages/Allocation/WX/Audit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/cc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__CC' */'@/pages/Allocation/CC'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/cc/distribution",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__CC__Distribution' */'@/pages/Allocation/CC/Distribution'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/cc/audit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__CC__Audit' */'@/pages/Allocation/CC/Audit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/cc/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__CC__Detail' */'@/pages/Allocation/CC/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/summary",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__Summary' */'@/pages/Allocation/Summary'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/piece",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__Piece__list' */'@/pages/Allocation/Piece/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/alloc/piece",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Allocation__User' */'@/pages/Report/Allocation/User'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/alloc/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Allocation__User__detail' */'@/pages/Report/Allocation/User/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/make/stage/price/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Make__Stage' */'@/pages/Report/Make/Stage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/make/stage/price",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Make__Stage__price' */'@/pages/Report/Make/Stage/price'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/make/group/done/zc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Make__Done__zc' */'@/pages/Report/Make/Done/zc'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/make/group/done/cx",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Make__Done__cx' */'@/pages/Report/Make/Done/cx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/salaryBase",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__SalaryBase' */'@/pages/Allocation/SalaryBase'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/salary",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__Salary__List' */'@/pages/Allocation/Salary/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/salary/piece",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__Salary__Piece' */'@/pages/Allocation/Salary/Piece'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/allocation/salary/addon",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Allocation__SalaryAddon' */'@/pages/Allocation/SalaryAddon'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/materialMatching",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__MtMatch' */'@/pages/Report/MtMatch'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/materialMatching/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__MtMatch__Detail' */'@/pages/Report/MtMatch/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/make/progress",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Make__Progress' */'@/pages/Report/Make/Progress'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/match",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__PartMatch' */'@/pages/Report/PartMatch'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/zc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Report__Part__list' */'@/pages/Dispatch/Work/Section/ZC/Report/Part/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/zc/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZC__Report__Part__detail' */'@/pages/Dispatch/Work/Section/ZC/Report/Part/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/cx",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CX__Report__Part__list' */'@/pages/Dispatch/Work/Section/CX/Report/Part/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/cx/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CX__Report__Part__detail' */'@/pages/Dispatch/Work/Section/CX/Report/Part/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/dnc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__DNC__Report__Part__list' */'@/pages/Dispatch/Work/Section/DNC/Report/Part/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/dnc/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__DNC__Report__Part__detail' */'@/pages/Dispatch/Work/Section/DNC/Report/Part/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/cc/stage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__CC__Report__Part__stage' */'@/pages/Dispatch/Work/Section/CC/Report/Part/stage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/sg/stage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__SG__Report__Part__stage' */'@/pages/Dispatch/Work/Section/SG/Report/Part/stage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/wx/stage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__WX__Report__Part__stage' */'@/pages/Dispatch/Work/Section/WX/Report/Part/stage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/part/dnc/stage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__DNC__Report__Part__stage' */'@/pages/Dispatch/Work/Section/DNC/Report/Part/stage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/cxSpeed",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Speed__CX' */'@/pages/Report/Speed/CX'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/zcSpeed",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Speed__ZC' */'@/pages/Report/Speed/ZC'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/zcDaySpeed",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Speed__Day__ZC' */'@/pages/Report/Speed/Day/ZC'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/cxDaySpeed",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Speed__Day__CX' */'@/pages/Report/Speed/Day/CX'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/device/list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Device__List' */'@/pages/Device/List'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/device/repair",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Device__Repair' */'@/pages/Device/Repair'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/allocation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Allocation__Group' */'@/pages/Report/Allocation/Group'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/allocationDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Allocation__GroupDetail' */'@/pages/Report/Allocation/GroupDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/instruct",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Instruct' */'@/pages/Report/Instruct'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/instructDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Instruct__Detail' */'@/pages/Report/Instruct/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/warn/rule",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Warn__Rule' */'@/pages/Warn/Rule'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/warn/rule/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Warn__Rule__detail' */'@/pages/Warn/Rule/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/board/workshop",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__FullScreen__Workshop' */'@/pages/Report/FullScreen/Workshop'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/work/yield",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Target__Yield' */'@/pages/Target/Yield'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/work/yield/report/zc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Target__Report__zc' */'@/pages/Target/Report/zc'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/work/yield/report/cx",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Target__Report__cx' */'@/pages/Target/Report/cx'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/make/plan",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Make__Plan' */'@/pages/Make/Plan'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/make/plan/progress",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Make__Plan__progress' */'@/pages/Make/Plan/progress'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/bom/lossStatement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Bom__LossStatement' */'@/pages/Report/Bom/LossStatement'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/bom/lossStatement/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__Bom__LossStatement__Detail' */'@/pages/Report/Bom/LossStatement/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/work/outsoleInventory",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Work__OutsoleInventory__index' */'@/pages/Work/OutsoleInventory/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/work/outsoleInventory/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Work__OutsoleInventory__Detail' */'@/pages/Work/OutsoleInventory/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/work/outsoleInventory/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Work__OutsoleInventory__Edit' */'@/pages/Work/OutsoleInventory/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/report/outsoleInventory",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report__OutsoleInventory' */'@/pages/Report/OutsoleInventory'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zczl",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZCZL' */'@/pages/Dispatch/Work/Section/ZCZL'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zczl/add",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZCZL__Edit__add' */'@/pages/Dispatch/Work/Section/ZCZL/Edit/add'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zczl/edit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZCZL__Edit' */'@/pages/Dispatch/Work/Section/ZCZL/Edit'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dispatch/work/zczl/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dispatch__Work__Section__ZCZL__Detail__index' */'@/pages/Dispatch/Work/Section/ZCZL/Detail/index.tsx'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
