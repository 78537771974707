
export default {

  stage: {
    createdAt: '创建日期',
    kindCode: '工种',
    levelCode: '工序级别',
    kindName: '工种',
    levelName: '工序级别',
    machine: '机器',
    partCode: '部件',
    partName: '部件',
    remark: '备注',
    rowState: '状态',
    sectionCode: '工段',
    sectionName: '工段',
    stageCode: '工序编码',
    stageName: '工序名称',
    standardPrice: '标准单价',
    standardTime: '标准工时',
    // standardTime: '应用部件工艺分组',
    // sort: '排序',
  },

  styleStage: {
    createdAt: '创建日期',
    remark: '备注',
    rowState: '状态',
    stageCode: '工序编码',
    stageName: '工序名称',
    stageId: '工序ID',
    stagePrice: '计件单价',
    stageTime: '标准工时',
    styleCode: '款号',
    styleId: '款式ID',
    styleName: '品名'
  },

  style: {
    createdAt: '创建日期',
    remark: '备注',
    rowState: '状态',
    brandName: '品牌',
    styleCode: '款号',
    styleNameAndCode: '款式',
    styleName: '品名',
    images: '款式图片',
    categoryCode: '品类',
    categoryName: '品类',
    heelpiece: '后跟',
    outsole: '大底',
    seasonCode: '产品季',
    seasonName: '产品季',
    shoeLast: '楦头',
    sexCode: '性别',
    dieCutter: '冲刀',
  },

  stageSection: {
    dataCode: '工段编码',
    dataName: '工段名称',
    pgDim: '派工维度',
    pgType: '派工目标',
    sortNumber:'排序',
    isLine:'是否应用工艺路径',
  },

  dispatchWork: {
    dispatchOrderCode: '派工单号',
    dispatchTime: '派工日期',
    factoryName: '生产工厂',
    produceBatchName: '批次',
    dispatchGroupName: '生产组别',
    stageName: '工序',
    makeCode: '关联生产单号',
    makePlan: '关联生产计划',
    trainNumber: '车次',
    sectionName: '工段',
    dispatchCount: '派工数量',
    dispatchDimension: '派工维度',
    instructCode: '指令单号',
    styleCode: '款号',
    colorName: '颜色',
    rowState: '状态'
  },

  styleBom: {
    createdAt: '创建日期',
    remark: '备注',
    rowState: '状态',
    bomName: 'BOM名称'
  },

  styleTechnics: {
    createdAt: '创建日期',
    remark: '备注',
    rowState: '状态',
    versionName: '版本'
  },

  styleTechnicsDetail: {
    technicsCode: '工艺编码',
    technicsName: '工艺名称',
    images: '图片',
    remark: '备注'
  },

  bomMaterial: {
    createdAt: '创建日期',
    remark: '备注',
    materialCode: '材料编码',
    materialName: '材料名称',
    usePartCode: '部位',
    usePartName: '部位',
    consumption: '单耗',
    waste: '耗损',
    colorName: '材料颜色',
    sizeName: '材料尺码',
    dimensionName: '款式颜色',
    unitName: '单位',
    spec: '规格'
  },

  material: {
    materialCode: '材料编码',
    materialName: '材料名称',
    typeName: '材料分类',
    unitName: '单位',
    spec: '规格'
  },

  make: {
    rowState: '状态',
    makeCode: "生产单号",
    factoryCode: "工厂编码",
    factoryName: "生产工厂",
    deliveryTime: "交货日期",
    //款号
    styleCode: "款号",
    //款式名称
    styleName: "品名",
    colorCode: "颜色编码",
    colorName: "颜色",
    shoeLast: "楦头",
    heelpiece: "后跟",
    outsole: "大底",
    orderTypeId: "订单类型id",
    orderTypeName: "订单类型",
    saleId: "销售单号-客户订单Id",
    //销售单号-客户订单
    saleCode: "客户订单",
    remark: "备注",
    attachment: "附件",

    saleItemId: "销售单项目id",
    sizeCode: "尺码编码",
    sizeName: "尺码",
    //销售项目数量
    saleItemCount: "订单数量",
    prepareCount: "预补数量",
    instructCode: "指令单号",
    dieCutter: "冲刀",
    seasonCode: '产品季编码',
    seasonName: '产品季',
    sizeSort: '排序',
    sizeNumber: '箱数',
    boxCode: '配码',
  },

  ColorModel: {
    colorCode: '颜色编码',
    colorId: '颜色名称',
    colorName: '颜色名称',
    materialCode: '材料编码',
    materialId: '材料Id',
    remark: '备注',
    rgbValue: 'RGB',
    cmykValue: 'CMYK',
    hexValue: 'HEC',
    hsvValue: 'HSX',
    pantoneValue: '潘通色',
    colorDiv: '颜色',
    barCode: '配码',
    rowState: '状态',
  },

  makeBom: {
    styleCode: "款号",
    bomName: 'BOM名称',
    styleBomMaterialId: '款式skuId',
    dimensionId: '款式skuID',
    bomId: 'bomID',
    styleId: '款式ID',
    materialId: '材料ID',
    materialCode: '材料编码',
    materialName: '材料名称',
    consumption: '耗量',
    waste: '耗损',
    dimensionCode: '颜色编码',
    dimensionName: '颜色名称',
    usePartCode: '部位编码',
    usePartName: '部位',
    spec: '规格',
    unitName: '单位',
    mtSizeName: '材料尺码',
    mtColorName: '材料颜色',
    num: '序号',
    // materialDimensionId,
  },

  makeBatch: {
    rowState: '状态',
    makeCode: "生产单号",
    instructCode: "指令单号",
    styleCode: "款式编码",
    styleName: "款式名称",
    colorCode: "颜色编码",
    colorName: "颜色",
    sizeCode: "尺码编码",
    sizeName: "尺码",
    batchName: "批次",
    batchCount: "批次数量",
    makeItemId: "生产订单项目",
    remark: "备注",
  },

  lineStage: {
    rowState: "状态",
    lineId: "款式工艺路线ID",
    styleId: "款式工艺ID",
    styleCode: "款式工艺编码",
    styleName: "款式工艺名称",
    stageId: "工序ID",
    stageCode: "工序编码",
    stageName: "工序名称",
    stagePrice: "工序价格",
    stageTime: "工序标准工时",
    stageSort: "工序顺序",
    sectionCode: "工段编码",
    sectionName: "工段",
    partCode: "部件编码",
    partName: "部件",
    materialCode: "物料编码",
    materialName: "物料",
    colorCode: "颜色编码",
    colorName: "颜色",
    remark: "备注",
  },

  qualityDefect: {
    rowState: '状态',
    qualityDefectCode: '缺陷编号',
    qualityDefectName: '缺陷名称',
    gradeCode: '缺陷等级',
    gradeName: '缺陷等级',
    typeCode: '缺陷分类',
    typeName: '缺陷分类',
    processSectionCode: '工序/段别',
    processSectionName: '工序/段别',
    remark: '备注',
    attachment: "附件",
  },

  checkRecord: {
    createdAt: '创建时间',
    rowState: '状态',
    remark: '备注',
    attachment: "附件",
    checkRecordCode: "品检单号",
    inspectTypeCode: "检验类型编码",
    inspectTypeName: "检验类型",
    inspectUserCode: "检验人编码",
    inspectUserName: "检验人",
    inspectTime: "检验日期",
    timeIntervalCode: "时段编码",
    timeIntervalName: "时段",
    processSectionCode: "工序/段别编码",
    processSectionName: "工序/段别",
    workshopCode: "车间编码",
    workshopName: "车间",
    groupCode: "组别编码",
    groupName: "组别",
    makeCode: "生产订单号",
    inspectCount: "检验数量",
    qualityDefectCount: "缺陷数量",
    defectiveRateCount: "不良率",
  },
  allocation: {
    rowState: '状态',
    groupCode: '组别',
    groupName: '组别',
    allocationAt: '日期',
    makeCode: '生产单编码',
    instructCode: '指令单号',
    styleCode: '款号',
    colorCode: '状态',
    colorName: '颜色',
    allocationCount: '数量',//分配数量
    inAllocationCount: '已分配数量',//已分配数量
    dispatchOrderId: '派工单号',
    dispatchOrderCode: '派工单号',
    sectionCode: '工段',
    stageCode: '工序',
    stageName: '工序',
    remark: '备注',

    pieceAllocationId: '件资分配',
    partName: '部件',
    allocationUserCode: '人员编码',
    allocationUserName: '分配人员',
    allocationUserCount: '分配人员数量',
    stagePrice: '工序单价',
  },
  salaryBase:{
    basePay:'基本工资',
    employeeCode:'工号',
    employeeName:'员工名称',
    liveAllowance:'外住补贴',
    payrollMethodCode:'计薪方式',
    payrollMethodName:'计薪方式',
    remark:'备注',
    rowState:'状态',
    seniorityAllowance:'工龄补贴',
    employeeStateCode:'员工状态',
    employeeTypeCode:'员工类型',
    orgCode:'组织编码',
    orgName:'组织名称',
  },
  craft:{
    dataCode:'编码',
    dataName:'名称',
    remark:'备注',
    rowState:'状态',
    sectionCode:'工段',
    sectionId:'工段',
    sectionName:'工段',
    sortNumber:'排序',
  },
  lineBase:{
    lineName:'款式工艺路线名称',
    styleCode:'款号',
    styleName:'款号名称',
    categoryCode:'品类编码',
    categoryName:'品类',
    colorCode:'颜色编码',
    colorName:'颜色',
    remark:'备注',
    rowState:'状态',
    version:'版本',

    // 工序工价
    levelCode:'工序级别',
    levelName:'工序级别',
    machine:'机器',
    sectionCode:'工段',
    sectionName:'工段',
    stageCode:'工序编码',
    stageName:'工序名称',
    stagePrice:'单价',
    stageTime:'工时',
    stageTypeCode:'工序类型',
    stageTypeName:'工序类型',
    stageRowState:'审核状态',
  }
};
