
import { useDispatch, useSelector } from 'umi';
import { IModel, ModelReducer, ModelState, ModelStateSelector } from '../model';
// import merge from 'lodash/merge';

export interface LineStageState extends ModelState {
  tableData: any[];
  tableLoading: boolean;
  optionStage: any[];
  selRows: any[];
  delIds: any[];
}

export interface ILineStageModel extends IModel<LineStageState> {
  effects: {};

  reducers: {
    init: ModelReducer<LineStageState>;
    save: ModelReducer<LineStageState>;
    addDelId: ModelReducer<LineStageState>;
  };
}

export const LineStageStateSelector: ModelStateSelector<LineStageState> = (state: any) => state.LineStage;

export function useLineStageSelector () {

  return useSelector(LineStageStateSelector);
}

export function useLineStageReducers (): (action: keyof ILineStageModel['reducers'], payload?: any) => any {
  const dispatch = useDispatch();

  return (action, payload) => {
    return dispatch({
      type: `LineStage/${action}`,
      payload
    });
  }
}

export function useLineStageEffects(): (action: keyof ILineStageModel['effects'], payload?: any) => Promise<any> {
  const dispatch = useDispatch();

  return (action, payload) => {
    return dispatch({
      type: `LineStage/${action}`,
      payload
    }) as any;
  }
}

const LineStageModel: ILineStageModel = {
  state: {
    tableData: [],
    tableLoading: false,
    optionStage: [],
    selRows: [],
    delIds: [],
  },

  effects: {},

  reducers: {
    init(state, { payload }) {
      return {
        tableData: [],
        tableLoading: false,
        optionStage: [],
        selRows: [],
        ...payload
      };
    },

    save(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },

    addDelId(state, { payload }) {
      return {
        ...state,
        delIds: state.delIds.concat(payload)
      };
    }
  }
};

export default LineStageModel;
