
export default {
  BomRowState: {
    WAITING: '待审核',
    CHECKED: '已审核',
    USED: '当前使用'
  },

  StyleTechnicsRowState: {
    WAITING: '待审核',
    CHECKED: '已审核',
    USED: '当前使用'
  },

  EntrustApplyState: {
    WAITING: '待审核',
    CHECKED: '已审核',
    USED: '当前使用'
  },

  DispatchApplyState: {
    DISABLED: '待审核',
    ENABLED: '已审核',
  },

  StyleStageState: {
    WAITING: '待审核',
    CHECKED: '已审核',
  },

  StageSectionDim: {
    KSM: '款色码',
    KSBM: '款色部码'
  },

  StageSectionType: {
    GROUP: '组',
    PERSION: '人'
  },

  DispatchWorkState: {
    WAITING: '待审核',
    CHECKED: '已审核',
    ALREABY_DISPATCH: '已审核',
    DISPATCH_COMPLETE: '已完工'
  },

  WarnRuleType: {
    PURCHASE_ORDER: '采购进度',
    PRODUCE_ORDER: '生产进度',
    QUALITY_CHECK: '质量检测',
    GROUP_QUANTITY_PROBLEM: '小时产能+不良统计',
    GROUP_QTY_PBM_SUM: '产量汇总'
  },

  MessageChannel: {
    WEB: '站内',
    WECHAT: '微信',
    DING_TALK: '钉钉',
    SMS: '短信'
  },

  WarnRuleQualityGroupType: {
    GROUP: '组别',
    WORKSHOP: '车间'
  },
}
