export default {
  style: {
    stage: {
      form: {
        prod: '生产款式',
        stage: '工序',
        price: '工序工价',
        time: '标准工时',
        remark: '备注',
        status: '状态'
      }
    },

    bom: {
      editBom: '编辑BOM',
      materialInfo: '物料信息',
      remarkInfo: '备注信息',
      addMaterial: '添加物料',
      copy: '复制BOM',
      copyWarn: '复制BOM会删除现有BOM数据',
      selectBom: '选择BOM'
    },

    tab: {
      list: '款式列表',
      sku: 'SKU'
    },

    sku: {
      color: '颜色',
      size: '尺码'
    }
  },

  make: {

    add: {
      addSaleCode: '选择客户订单',

      card: {
        saleDetail: '详情信息',
        remarkInfo: '备注信息',
        materialInfo: '物料信息',
        packNumInfo: '装箱配码',
      },

      modal: {
        title: '选择客户订单'
      }

    }


  }
}
