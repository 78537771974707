
export default {
  'BD_20080': '颜色名称已经存在',
  'MT_40030': '物料名称已存在',
  'MM10010': '存在相同数据的相同批次！',
  'api.code.ie10001': '派工单打印码已存在',
  'IE20000': '存在入库数量不可以进行反审！',
  'MM20050': '相同款式存在不同的bom！',
  'ie_30002': '未找到有效数据',
};
